// variables
$primary-color: #53a9dc;
$secondary-color: #fdde50;
$tertiary-color: #282c35;
$quaternary-color: #1e2126;
$text-color: #ffffff;
$paragraph-color: rgb(202, 202, 202);

// mixins to help make the code less repetitive
@mixin flexCenter($direction) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: $direction;
}

// medium screen size media query
@mixin medium {
    @media only screen and (max-width: 1270px) {
        @content;
    }
}

// mobile screen media query
@mixin mobile {
    @media only screen and (max-width: 720px) {
        @content;
    }
}

$spacing-unit: 16px;  

.homeContainer {
    width: 100%;
    background-color: $quaternary-color;

  header {
    display: flex;
    min-height: 380px;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 50px;

    //padding on the left of the logo on header

    // medium screen size media query
    @include medium {
      @include flexCenter(column);
    }

    // mobile screen media query
    @include mobile {
      padding: $spacing-unit;
      max-width: 100%;
      min-height: 300px;
      margin-top: $spacing-unit * 2.75;
      flex-direction: column;
      justify-content: center;

      button {
        font-size: $spacing-unit * 2;
        padding: $spacing-unit $spacing-unit * 2;
      }
    }
  }
}
  
.details-text {
    height: 100%;
    width: 100%;
    flex: 1;

    @include flexCenter(column);

    h1 {
        margin-top: 0;
        font-size: 52px;
        color: $text-color;
        font-weight: bolder;
        text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.8);
        margin-bottom: 1%;
        text-align: center;
    }

    p {
        font-size: 24px;
        color: $text-color;
        text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.8);
        font-weight: bolder;
        text-align: center;
    }

    @include mobile {
        margin: 0;

        h1 {
            font-size: 40px;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        p {
            font-size: 20px;
            margin-bottom: 15px;
        }
    }
}
  
.download {
    @include flexCenter(column);
    flex: 1;
    flex-direction: row;
    gap: 15px;
    
    a {
        color: inherit;
        text-decoration: none;
    }

    button {
        font-size: 30px;
        transition: all 0.3s ease;
        padding: 30px 60px;
        background-color: $secondary-color;
        color: #282c35;
        cursor: pointer;
        border-radius: 3px;
        border: none;
        position: relative;
        @include flexCenter(column);

        &:hover {
            background-color: orange;
            transform: translateY(7px);
        }

        span {
            margin-top: 1%;
            color: rgba(0,0,0,0.7);
            font-size: 25px;
        }

        // mobile screen media query
        @include mobile {
            margin: 0;
            font-size: 20px !important;
            span {
                font-size: 15px !important;
            }
        }
    }
}

/* Content part */
.content {
    padding: 2%;
    min-height: 900px;
    display: flex;
    flex-direction: row;
    background-color: $tertiary-color;
    width: 100%;
    align-items: flex-start;
    height: calc(100vh - 50px);
  
    @include medium {
      flex-direction: column;
      min-height: 90vh;
      height: auto;
      padding: 5% 2%;
    }
  
    @include mobile {
      height: auto;
      width: 100%;
      padding: 5% 2%;
    }
}

a {
    text-decoration: none;
}
  
#homeText {
  height: 100%;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 1%;
  justify-content: center;

  h1 {
      margin-bottom: 20px;
      font-size: 250%;
      text-align: left;
      color: $text-color;
  }

  p {
      padding-bottom: 5%;
      font-size: 125%;
      text-align: left;
      color: $paragraph-color;
  }

  @include mobile {
      p {
          font-size: 16px;
      }

      h1 {
          font-size: 24px;
      }
  }
}

.buttonIcon {
    color: $primary-color;
    font-size: 3em;
    margin-left: 0.1em;
    margin-right: 0.2em;
}

.buttonContent {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
}

.buttonText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-grow: 1;
    color: $text-color;

    h2 {
        margin-top: 0;
        margin-bottom: 0.5em;
        font-size: 1.5em;
        color: $text-color;
    }
}

.buttonSubtitle {
    margin-top: 0;
    font-size: 0.8em;
    color: $paragraph-color;
}

span {
    font-size: 1em;
    font-weight: bold;
}

#homeButtons {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 2%;
  
    button {
      width: 90%;
      max-height: 14%;
      height: 100px;
      font-size: 1.5em;
      border: none;
      border-radius: 0.5em;
      cursor: pointer;
      transition: transform 0.2s ease;
      background-color: #2b4f65;
  
      &:hover {
        // make the button bright when hovered
        filter: brightness(2);
        transform: translateY(7px);
      }
    }
  
    @include medium {
      padding: 0 1%;
      align-items: center;
      gap: 1%;
  
      button {
        width: 100%;
        height: 4em;
        font-size: 1.2em;
      }
    }
  
    @include mobile {
      align-items: center;
      gap: 2%;

      button {
        width: 100%;
        height: max(4em, 10vw);
        font-size: max(1em, 1vw);
        padding: 0.5em 1em;
      }
    }
}