.inputStyle {
    text-align: left;
    background: white;
    font-size: 13px;
    font-weight: bold;
    width: 155px;
    height: 22px;
    background-color: #2b4f65;
    border-color: #1e2126;
    color: white;
    margin-bottom: 0px;
}

.labelStyle {
    display: inline-block;
    width: 130px;
    font-size: 18px;
}

.buttonStyle {
  width: 100px;
  height: 45px;
  margin-left: 5px;
  margin-right: 5px;
  background-color: #53a9dc;
  border-color: #1e2126;
  color: white;
  font-size: 12px;
}

.buttonStyleHover:hover {
  filter: brightness(0.8);
}

.extensionStyle {
    font-size: 18px;
    margin-left: 5px;
}
  

.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #282c35;
    padding: 2rem;
    border-radius: 4px;
    width: 80%;
    max-width: 500px;
    outline: none;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
  }
  
  .modal input[type="text"] {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: #3b4049;
    color: #fff;
  }
  
.modal button {
  background-color: #53a9dc;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  transition: filter 0.3s ease;
}

.modal button:hover {
  filter: brightness(0.8);
}
  
  .modal h2 {
    color: #fff;
    margin-bottom: 15px;
  }

  .modal textarea {
    resize: none;
  }
  
  .modal p {
    color: #fff;
  }
  
  