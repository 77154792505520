/* Table Styles */
table {
    border-collapse: collapse;
    width: 100%;
  }
  
  th,
  td {
    text-align: left;
    padding: 8px;
    border: 2px solid #1e2126;
    color: #ffffff;
  }
  
  th {
    background-color: #fdde50;
    color: #282c35;
  }
  
  tr:hover {
    background-color: #1e2126;
  }
  
  tr:nth-child(even) {
    background-color: #282c35;
  }
  
  tr:nth-child(odd) {
    background-color: #1e2126;
  }
  
  /* Code Block Styles */
  code{
    background-color: rgb(30, 30, 30);
    border-radius: 4px;
    padding: 5px;
    font-family: monospace;
    font-size: 14px;
    line-height: 1.5;
    white-space: pre-wrap;
    color: white;
  }

  pre{
    /* not fully black for VA monitors */
    background-color: rgb(15, 15, 15);
    border-radius: 4px;
    padding: 10px;
    font-family: monospace;
    font-size: 14px;
    line-height: 1.5;
    white-space: pre-wrap;
    color: white;
  }
  
  pre .key {
    color: #f7c46c;
  }
  
  pre .string {
    color: #b5cee3;
  }
  
  pre .number {
    color: #b5cea8;
  }
  
  pre .boolean {
    color: #4ec9b0;
  }
  
  pre .comment {
    color: #6a9955;
  }
  
  h2 {
    color: white;
  }

  .divider {
    height: 2px;
    margin: 0.6rem;
}

a {
color: #53a9dc;
}
  
a:hover {
color: #fdde50;
}

a:active {
color: #53a9dc;
}
