.materialSearchContainer {
    width: 100%;
    height: 160px;
    padding: 5px;
    background-color: #2b4f65;
    margin-bottom: 10px;
}

.materialSearchGrid {
    width: 100%;
    max-height: 150px;
    display: grid;
    grid-template-columns: repeat(10, 10%);
    overflow-y: scroll;
}
  
.materialSearchGrid.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
}  

.imageWrapper {
    width: 48px;
    height: 48px;
    background-image: url("../../images/slot.png");
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.materialSearchGrid::-webkit-scrollbar {
    height: 0;
    background: transparent;
}

.materialSearchImage {
    width: 40px;
    height: 40px;
}

.imageWrapper:hover {
    background-image: url("../../images/slot_hover.png");
}