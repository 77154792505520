:root {
  --primary-color: #53a9dc;
  --secondary-color: #fdde50;
  --tertiary-color: #282c35;
  --quaternary-color: #1e2126;
  --text-color: #ffffff;
  --paragraph-color: rgb(202, 202, 202);
}

.downloadButtonsContainer {
  color: var(--text-color);
  background-color: var(--tertiary-color);
  padding: 20px;
  box-sizing: border-box;
  min-height: calc(100vh - 278px); /* Header is 278px */
}

.downloadsContainer header {
  color: var(--text-color);
  background-color: var(--quaternary-color);
  width: 100%;
  padding: 60px;
  margin-top: 50px; /* Because of the navbar */
  box-sizing: border-box;

}

.downloadsContainer header h1 {
  font-size: 52px;
  padding: 10px;
  text-align: center;
  color: var(--text-color);
}

.downloadsContainer header h2 {
  font-size: 24px;
  text-align: center;
  color: var(--paragraph-color);
}

.downloadsContainer header p {
  color: var(--paragraph-color);
}

.downloadItem {
  display: flex;
  justify-content: space-between;
  background-color: rgba(30, 33, 38, 0.4); /* quaternary-color with alpha channel */
  align-items: center;
  margin-top: 20px;
  padding: 40px;
  border-radius: 5px;
  transition: all 0.2s ease;
}

.downloadItem:hover {
  background-color: var(--quaternary-color);
}

.downloadTitle {
  color: var(--text-color);
  font-weight: bold;
  font-size: 28px
}

.downloadSubtitle{
  display: block;
  color: var(--paragraph-color);
  margin-top: 2px;
  font-size: 20px;
}

.downloadDescription{
  display: block;
  color: var(--paragraph-color);
  margin-top: 2px;
  font-size: 16px;
}

.downloadsPageButton {
  font-size: 30px; /* Adjusted to match the SCSS base font size for buttons */
  padding: 20px 30px; /* Matched to the SCSS padding */
  margin: 10px;
  background-color: var(--secondary-color); /* Using the CSS custom property equivalent */
  color: var(--tertiary-color); /* Using the CSS custom property equivalent */
  cursor: pointer;
  border-radius: 3px;
  border: none;
  transition: all 0.3s ease, transform 0.3s ease; /* Added transform transition for hover effect */
}

.downloadsPageButton span {
  display: block;
  color: rgba(0,0,0,0.7);
  font-size: 20px;
}

.downloadsPageButton:hover {
  background-color: orange; /* This replaces the secondary color on hover in the SCSS */
  color: var(--tertiary-color);
  transform: translateY(7px); /* Added the translateY transform on hover */
}

/* Responsive design adjustments */
@media (max-width: 600px) {
  .downloadItem {
      flex-direction: column;
  }
  .downloadTitle {
    font-size: 20px;
    text-align: center;
  }
  .downloadsPageButton {
    font-size: 20px; /* Adjusted font size for mobile */
    padding: 15px 30px; /* Adjusted padding for mobile */
  }
  .downloadsContainer header h1 {
    font-size: 30px;
  }
  
  .downloadsContainer header h2 {
    font-size: 18px;
  }
}