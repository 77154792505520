.partnerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Arial, sans-serif;
    min-height: 100vh;
    width: 100vw;
    background-color: #282c35;
    color: #ffffff;
    overflow: hidden;
    overflow-y: scroll;

    background-image: linear-gradient(
        rgba(30, 33, 38, 0.8),
        rgba(30, 33, 38, 0.8)
    ),
    url('../images/PartnerBackground.png');
    background-size: cover;
}


.partnerHeader {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #1e2126;
    padding: 20px 0;
    border-bottom: 5px solid #3c4252;
    }

.partnerLogo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.partnerLogo img {
    width: 100%;
    max-width: 450px;
    height: auto;
}

.partnerContentTitle {
    margin-top: 50px;
}



.partnerButton {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 30px 0;
  }
  
  .partnerButton button {
    background-color: #0077ff;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    font-size: 24px;
    padding: 20px 40px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
  }

  .partnerButton a {
    color: white;
    font-size: 27px;
  }
  
  .partnerButton button:hover {
    background-color: #00aeff;
  }
  
  .partnerButton h2 {
    font-size: 22px;
    margin-top: 20px;
    color: #ffffff;
  }
  

  .partner-content {
    min-height: 800px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.partnerDescription {
    padding: 80px;
    position: relative;
    margin-bottom: 30px;
}

.partnerContentTitle h1 {
    font-size: 50px;
    margin-bottom: 15px;
    color: #ffffff;
}

.partnerDescription h2 {
    font-size: 30px;
    margin-bottom: 15px;
    color: #ffffff;
    margin-top: 75px;
}

.partnerDescription p {
    font-size: 20px;
    line-height: 1.5;
    margin-bottom: 15px;
    color: #ffffff;
}
