.logic-creator {
    background-color: #2b4f65;
    color: #fff;
    max-height: 200px;
  }
  
  .condition select,
  .condition input[type="text"] {
    height: 20px;
    font-size: 14px;
    max-width: 185px;
    border: none;
    border-radius: 3px;
    background-color: #282c35;
    text-indent: 1px;
    color: #ffffff;
    margin-right: 10px;
  }
  
  .condition select {
    appearance: none;
    margin-right: 10px;
  }
  
  .condition:first-child input[type="text"] {
    margin-left: 10px;
  }
  
  .logic-creator button {
    margin-top: 5px;
    height: 40px;
    font-size: 15px;
    padding: 0 10px;
    margin-left: 17px;
    border-radius: 4px;
    background-color: #53a9dc;
    color: #ffffff;
    cursor: pointer;
  }
  
  .logic-creator button:hover {
    background-color: #ddd;
    color: #2b4f65;
  }
  
  .condition:not(:last-child) {
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  
  .condition:last-child {
    margin-bottom: 0;
  }