.command-panel {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #282c35;
  border: 2px solid #53a9dc;
  box-shadow: 2vw 2vh 2vw rgba(0, 0, 0, 0.4);
  width: 600px;
  height: 200px;
  text-align: left;
  border-radius: 10px;
  overflow: hidden;
  font-family: 'Arial', sans-serif;
  font-size: 18px;
}

.command-panel-header {
  background-color: #53a9dc;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.command-panel-title {
  font-size: 20px;
  font-weight: bold;
  text-shadow: 1px 1px #282c35;
  color: #fff;
}

.command-panel-body {
  padding: 20px;
}

.command-panel-text {
  margin-bottom: 10px;
  color: #fff;
}
