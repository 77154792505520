.appContainer {
  margin: 0;
  padding: 0;
  flex: 1 0 auto;
  width: calc(100vw - 10px);
  height: calc(100vh - 10px);
}

.routerContainer {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

/* webkit scrollbar */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #1e2126;
}

::-webkit-scrollbar-thumb {
  background-color: #53a9dc;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #fdde50;
}

footer {
  background-color: #1e2126;
  padding: 0px 10px;
  width: 100%;
}

@media (max-width: 650px) {
  footer .navbar-link {
    display: none;
  }
  footer .footerContent {
    justify-content: center;
  }
  footer p {
    padding: 10px;
  }
}

.footerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.information {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.information li {
  list-style-type: none;
  font-size: 16px;
  color: white;
}

.information li a {
  color: white;
}

.copyright {
  font-size: 16px;
  color: white;
}