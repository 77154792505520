.editable-label {
    cursor: pointer;
  }
  
  .emodal {
    position: fixed;
    top: 10%;
    left: 10%;
    right: 10%;
    bottom: 10%;
    background-color: #282c35;
    color: #fff;
    padding: 20px;
    border: none;
    border-radius: 10px;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }
  
  .emodal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 999;
  }
  
  .textarea {
    width: 100%;
    height: 100%;
    background-color: #2b4f65;
    border-color: #1e2126;
    color: white;
    resize: none;
    overflow: auto;
    overflow-wrap: normal;
    white-space: pre;
    overflow-x: scroll;
  }
  
  .close-button {
    width: 100px;
    height: 50px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .slider {
    width: 200px;
    margin: 10px 0;
  }
  
  .slider-container {
    display: flex;
    align-items: center;
  }
  
  .font-size {
    margin-left: 10px;
  }
  
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }