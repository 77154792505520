* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li {
  list-style: none;
  text-decoration: none;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  background-color: #282c35;
  height: 50px;
  padding: 0 20px;
  padding-right: 0px;
  border-bottom: 1px solid #1e2126;
  font-size: 25px;

  h1 {
    font-family: "Arial", sans-serif;
    font-size: 24px;
    color: white;
  }

  h2 {
    font-family: "Arial", sans-serif;
    font-size: 18px;
    color: white;
    transform: scale(1);
    transition: transform 0.3s ease-in-out;
  }

  p {
    font-family: "Arial", sans-serif;
    font-size: 12px;
    color: rgb(207, 207, 207);
  }

  .navbar-list {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    margin-right: 5px;
    padding: 0;
    flex-grow: 1;
    justify-content: flex-end;

    .navbar-item {
      margin: 0 5px;
    }

  }
}

.navbar-link {
  display: block;
  padding: 10px;
  text-decoration: none;
  transition: all 0.3s ease;
  font-size: 18px;
  font-family: Arial, sans-serif;
  color: white;

  &:hover {
    background-color: #fdde50;
    color: rgb(0, 0, 0);
    
    img {
      filter: brightness(0);
    }
  }

  img {
    height: 30px;
    display: block;
  }
}

.welcome-item {
  font-weight: bold;
  &:hover {
    cursor: pointer;
  }
}

.navbar-logo {
  height: 35px;

  &:hover {
    cursor: pointer;
  }
}

.navbar-logo-animate {
  cursor: pointer;
  animation: rainbowAnimation 3s infinite linear;

  &:hover {
    cursor: pointer;
  }
}

@keyframes rainbowAnimation {
  0% { filter: hue-rotate(0deg); }
  100% { filter: hue-rotate(360deg); }
}

.navbar-colour-popup {
  position: absolute;
  top: 50px;
  left: 10px;
  background-color: #282c35;
  color: white;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.navbar-colour-popup h4{
  font-size: 18px;
  font-weight: lighter;
}

.partner-favicon {
  height: 35px;
  border-left: 3px solid #53a9dc;
  margin-left: 15px;
  margin-right: 10px;
  padding-left: 15px;

  &:hover {
    cursor: pointer;
  }
}

nav > h1:hover {
  cursor: pointer;
}

nav > h2:hover {
  cursor: pointer;
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out;
}

.navbar-toggle {
  position: absolute;
  right: 20px;
  display: none;
  cursor: pointer;
}

.icon-bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: white;
  border-bottom: 3px solid transparent;
}

.active-link {
  position: relative;
  display: inline-block;
}

.active-link::after {
  content: '';
  position: absolute;
  bottom: -3px; /* Adjust based on your design */
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #fdde50;
  transform: scaleX(1); /* Make the underline always visible */
  transform-origin: bottom left; /* Start the transformation from the left */
  transition: transform 0.25s ease-out;
}

.active-link:hover::after {
  transform: scaleX(0); /* Make the underline disappear on hover */
  transform-origin: bottom right; /* End the transformation to the right */
}

//logo and partner text
@media (max-width: 1400px) {
  .navbar h1 {
    font-size: 0px;
  }
}

@media (max-width: 1000px) {
  .navbar-toggle {
    display: block;
    cursor: pointer;
  }

  .icon-bar {
    width: 30px;
    height: 2px;
    background-color: #fff;
    margin: 6px 0;
    transition: 0.2s;
  }

  .navbar-toggle.active .icon-bar:nth-child(2) {
    opacity: 0;
  }

  .navbar-toggle.active .icon-bar:nth-child(1) {
    transform: translateY(9px) rotate(45deg);
  }

  .navbar-toggle.active .icon-bar:nth-child(3) {
    transform: translateY(-9px) rotate(-45deg);
  }

  .navbar-list {
    position: fixed;
    right: -77%;
    top: 50px;
    gap: 0;
    flex-direction: column;
    background-color: #282c35;
    width: 75%;
    transition: 0.3s;
    padding: 10px;

    .navbar-item {
      width: 100%;

      .navbar-link {
        display: block;
        color: #fff;
        text-decoration: none;
        margin: 5px 0;
        font-size: 18px;
        font-weight: 500;
        transition: 0.2s;

        &:hover {
          background-color: #fdde50;
          color: rgb(0, 0, 0);
        }
      }
    }
  }

  .navbar-list.active {
    right: 0%;
    border: 2px solid #1e2126;
  }
}

// navbar media designed when displaying My Account
/* Hides .partner-favicon and .navbar h2 when the viewport width is below 1250px */
@media (max-width: 1250px) {
  .partner-favicon,
  .navbar h2 {
    display: none !important;
  }
}
/* Shows .partner-favicon and .navbar h2 when the viewport width is between 601px and 1000px */
@media (min-width: 601px) and (max-width: 1000px) {
  .partner-favicon,
  .navbar h2 {
    display: block !important; /* or use the original display property values */
  }
}
/* Hides .partner-favicon and .navbar h2 again when the viewport width is below 600px */
@media (max-width: 650px) {
  .partner-favicon,
  .navbar h2 {
    display: none !important;
  }
}


// popup window for profile
.popup-content {
  position: relative;
  padding: 5px;
  border: 1px solid #1e2126;
  background-color: #282c35;
  z-index: 9999;

  .navbar-link {
    display: flex;
    outline: none;
    align-items: center;
    text-decoration: none;
  }

  .popupElement {
    display: flex;
    align-items: center;
    padding: 0.25rem;
  }

  h3 {
    margin: 0;
  }
}