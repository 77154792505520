// variables
$primary-color: #53a9dc;
$secondary-color: #fdde50;
$tertiary-color: #282c35;
$quaternary-color: #1e2126;
$text-color: #ffffff;
$paragraph-color: rgb(202, 202, 202);

@mixin flexTop($direction) {
    display: flex;
    justify-content: center;
    align-items: top;
    flex-direction: $direction;
}

.activeType {
    background: $primary-color;
    color: white;
    margin-right: 20px;
}

.commands {
    @include flexTop(row);
}

.commandCodeBlockTypes {
    flex: 1;
}

.tagType {
    &:hover {
        cursor: pointer;
    }
}

.tag {
    margin-bottom: 10px;

    // used for dragging the tag anywhere
    position: absolute;
    top: 0;
    left: 0;
}

.commandCodeBlocks {
    overflow-y: scroll;
    height: 150px;
    flex: 1;
}

.tagContent {
    padding: 5px;
    border-radius: 10px;
    background: $primary-color;
    &:hover {
        background: lightgray;
        cursor: pointer;
    }
    h1 {
        font-size: 100%;
    }
}

.tagInfo {
    display: none;
}