.hasSectionsContainer {
    display: flex;
    flex-direction: column;
  }
  
  .sectionsList {
    display: flex;
    flex-direction: column;
    background-color: #2b4f65;
    overflow: scroll;
    color: white;
    font-size: 16px;
    padding:10px;
    width: 100%;
    height: 125px;
    margin-bottom: 15px;
  }
  
  .sectionItem {
    padding: 10px 15px;
    cursor: pointer;
  }
  
  .sectionActions {
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
    gap: 15px;
  }

  .sectionProperties {
    display: flex;
    background-color: #2b4f65;
    width: 100%;
    height: 225px;
    overflow: scroll;
    padding: 3px;
  }