@import 'authHelper.scss';

.authContainer {
  height: calc(100vh - #{$navbar-height});
  min-height: calc($small-screen-container-size);
  background-color: #1e2126;
  max-width: 100vw;
  display: flex;
  justify-content: center; // Center the container horizontally
  align-items: center; // Center the container vertically

  @media screen and (max-height: $small-screen-container-size) {
    overflow-y: scroll; // Enable vertical scrolling
  }
}

#loginContainer {
  @include formWithSize(35em, 45em);
}

.container > header {
  margin: none;
  padding: 0 100px;
  background-color: #53a9dc;
  border-radius: 10px 10px 0 0;
  display: none;
  display: block;
}

.body {
  padding: 30px;
}

.container .login_logo {
  display: block;
  margin: 0 auto;
  width: 100%;
}

h1 {
  text-align: center;
  font-size: 28px;
}

label {
  padding-top: 1%;
  font-size: 20px;
  margin-bottom: 5px;
}

form {
  display: flex;
  flex-direction: column;
}

input {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #1e2126;
  background-color: #2b4f65;
  color: white;
  border-radius: 5px;
  margin-bottom: 15px;
}

button {
  background-color: #53a9dc;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  font-family: "Arial", sans-serif;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 15px 0;
}

button:hover {
  background-color: #0056b3;
  color: #c8c8c8;
}

.signInBtn:hover {
  background-color: #2d5d79;
}

.signInBtn {
  align-self: center;
  justify-content: center;
  background-color: #282c35;
  border-color: #53a9dc;
  border: 2px solid;
  color: #53a9dc;
  font-size: 20px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  width: 100%;
}

.signIcon {
  font-size: 20px;
  margin-right: 10px;
}

.guest {
  margin-top: 1.5em;
  text-align: center;
}

.guestLink {
  color: #fff;
  text-decoration: underline;
}

.guestLink:hover {
  color: #53a9dc;
}

.signup {
  margin-top: 1em;
  text-align: center;
}

.signupLink {
  color: #ffffff;
}

.signupLink:visited {
  color: #ffffff;
}

.signupLink:hover {
  color: #53a9dc;
  text-decoration: underline;
}

hr {
  margin-bottom: 1.5em;
}

.login_logo {
  width: 100%;
  height: 100%;
  padding: 20px 0px 20px 0px;
}

.forgotPassword {
  margin-top: 1em;
  text-align: center;
}

.forgotPasswordLink {
  color: #ffffff;
}

.forgotPasswordLink:hover {
  color: #53a9dc;
  text-decoration: underline;
}