$navbar-height: 100px;
$small-screen-container-size: 1000px;

@mixin formWithSize($width, $height) {
  background-color: #282c35;
  color: #fff;
  border-radius: 10px;
  width: 80%;
  max-width: $width;
  max-height: $height;
  box-shadow: 2vw 2vh 2vw rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media screen and (max-height: $small-screen-container-size) {
    margin-top: 100px;
    margin-bottom: 200px;
    top: initial; // Remove the top position for vertical scrolling
    transform: translate(-50%, 0); // Adjust the vertical position
  }
}
