.editPanelContainer {
    margin-top: 50px;
    min-width: 100vw;
    min-height: calc(100vh - 50px);
}

.editPanelContainer > h1 {
    text-align: left;
    margin-top: 0;
    font-size: 30px;
    color: white;
    margin: 2% 10%;
}

.editPanelHeader {
    margin: 0 10%;
    background-color: #53a9dc;
    padding: 0 5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 5%;
    flex-wrap: wrap;
}

#search {
    margin-top: 6%;
    margin-left: 1em;
    color: white;
}

.editPanelHeader button {
    margin-top: 1%;
    font-size: 100%;
    height: 50%;
    background-color: #fdde50;
    max-width: 20%;
    color: black;
    font-weight: bolder;
}

.editPanelHeader > button:hover {
    background-color: #f7c500;
}

#filterPanelsBtn {
    margin-left: 1em;
    flex: 1;
}

.panelDashboard {
    margin: 0 10%;
    height: 70vh;
    background-color: #2b4f65;
    padding: 0.5%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10%;
    flex-wrap: wrap;
}

.panelList {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: #282c35;
    overflow-y: scroll;
}

li.panelItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #2b4f65;
    margin: 0.5em 0.5em;
    padding: 0 2.5%;
    color: white;
    font-size: 20px;
    flex-wrap: wrap;
}

.panelItemButtons {
    display: flex;
    flex-direction: row;
    gap: 10%;
    justify-content: center;
}

.panelItemButtons > button {
    width: 5rem;
}

li.panelItem button:hover {
    cursor: pointer;
    background-color: #fdde50;
    color: black;
}

li.panelItem button {
    background-color: #53a9dc;
}

.panelItemButtons .deleteButton {
    margin-top: 6%;
    background-color: #7e4242;
}

.panelItemButtons .deleteButton:hover {
    background-color: #fdde50;
    color: black;
}

.panelItemButtons .editButton {
    margin-top: 6%;
    background-color: green;
}

.panelItemButtons .editButton:hover {
    background-color: #fdde50;
    color: black;
}

.panelItemButtons .downloadButton {
    margin-top: 6%;
    width: 3rem;
    background-color: #2b4f65;
}

.panelItemButtons .downloadButton:hover {
    background-color: #fdde50;
    color: black;
}

/* sweet alert 2 popups */

div.swal2-popup {
    min-width: 300px;
    max-width: 550px;
    width: 40vw;
    border: 10px #53a9dc solid;
    background-color: #282c35;
    color: lightgray;
}

h2.swal2-title {
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
}

.swal2-content {
    font-size: 1.2rem;
}

.swal2-actions {
    gap: 1rem;
}

.deletePanel .swal2-confirm {
    background-color: red !important;
    color: #fff;
}

.createPanel .swal2-confirm {
    background-color: green !important;
    color: #fff;
}

.swal2-cancel {
    background-color: #2b4f65 !important;
    color: black;
}

.deletePanel .swal2-icon {
    border-color: red !important;
    color: red !important;
    filter: drop-shadow(0 0rem 1rem red);
}

.noPremiumMessage {
    color: red;
    font-size: 1.2rem;
    font-weight: bold;
}

/* Media queries for responsive design */

@media (max-width: 768px) {

    .editPanelContainer {
        height: 105vh;
    }

    .editPanelHeader {
        padding: 1rem 2rem;
    }

    .editPanelHeader > span {
        width: 100%;
    }

    .editPanelHeader > span > input {
        width: 80%;
    }

    .editPanelHeader {
        flex-direction: column;
    }

    #filterPanelsBtn {
        margin-left: 0;
        margin-top: 4rem;
    }

    .editPanelHeader button {
        max-width: 100%;
        width: 100%;
    }

    .panelItemButtons {
        min-width: 10em;
        width: 50%;
    }
}

@media (max-width: 480px) {

    .editPanelHeader > span > input {
        padding-left: 10%;
        width: 80%;
    }

    .editPanelContainer > h1 {
        font-size: 24px;
    }

    li.panelItem {
        flex-direction: column;
    }

    .panelItemButtons {
        margin-top: 4rem;
    }
}
