body {
  font-family: Arial, sans-serif;
  background-color: #1c1f24;
}

.page-container {
  display: flex;
  flex-direction: column;
  height: 94.5vh;
}

.top-container {
  display: flex;
  flex-direction: row;
  flex: 0.7;
}

.top-left-container {
  border: 1px solid white;
  flex: 0.2;
}

.top-right-container {
  border: 1px solid white;
  flex: 0.8;
  display: flex;
  align-items: stretch;
}

.bottom-container {
  border: 1px solid white;
  flex: 0.3;
}

#hotbarItemLabel {
  height: 2em;
}