.uploadModifyContainer_unique {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    border-radius: 5px;
    margin-top: 100px;
  }

  /* stop footer showing inside loading bar */
  .uploadPanelPageLoading{
    margin-bottom: 100vh;
  }
  
  .uploadModifyForm_unique {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    gap: 15px;
    background-color: #1e2126;
    padding: 20px;
    border-radius: 5px;
  }
  
  .uploadModifyLabel_unique {
    display: flex;
    flex-direction: column;
    gap: 5px;
    color: #ffffff;
    font-size: 16px;
  }
  
  .uploadModifySelect_unique,
  .uploadModifyInput_unique,
  .uploadModifyTextarea_unique {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #1e2126;
    background-color: #2b4f65;
    color: white;
    border-radius: 5px;
  }
  
  .uploadModifyTextarea_unique {
    height: 300px;
    resize: none;
  }
  
  .uploadModifyButton_unique {
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .uploadModifyButtonSubmit_unique {
    background-color: #53a9dc;
    color: #ffffff;
  }
  
  .uploadModifyButtonDelete_unique {
    background-color: #fdde50;
    color: #282c35;
  }
  
  .uploadModifyButton_unique:hover {
    filter: brightness(1.1);
  }