.window {
    background-color: #282c35;
    border: 1px solid #1c1f24;
    display: flex;
    flex-direction: column;
    position: absolute;
  }
    
  .window-header {
    background-color: #2b4f65;
    color: #ffffff;
    font-weight: bold;
    font-family: "Arial", sans-serif;
    cursor: move;
    display: flex;
    justify-content: space-between;
    padding: 4px;
    height: 25px;
  }

  .window-header:hover {
    background-color: #53a9dc;
  }
    
  .window-content {
    color: #fff;
    padding: 5px;
    padding-top: 5px;
  }
    
  .window-header button {
    background-color: #282c35;
    border-radius: 3px;
    color: #fff;
    font-size: 12px;
    font-family: "Arial Black", sans-serif;
    padding: 6px 10px;
    margin-top: 0px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }
    
  .window-header button:hover {
    background-color: #fdde50;
    color: #000000;
  }
  