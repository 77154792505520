.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .loading-text {
    color: white;
    font-size: 2em;
    margin-top: 1em;
  }
  
  .loading-spinner {
    border-radius: 50%;
    border: 20px solid #ffde50;
    border-top-color: transparent;
    animation: spin 0.77s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }