.repoContainer {
  background-color: #282c35;
  padding: 50px 15px 50px 15px;
  flex-grow: 1;
  box-sizing: border-box;
}

.repoContent {
  color: #ffffff;
  text-align: center;
  position: relative;

  section {
    margin-bottom: 40px;
  }

  .headerTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    h1 {
      color: #53a9dc;
    }

    .uploadButton {
      margin: 0;
      background-color: #53a9dc;
      border: none;
      border-radius: 5px;
      color: #ffffff;
      padding: 10px 20px;
      cursor: pointer;
      transition: background-color 0.2s ease-in-out;

      &:hover {
        background-color: #fdde50;
        color: black;
      }
    }
  }

  .headerContainer {
    text-align: left;

    p {
      margin: 10px 0;
      color: rgb(202, 202, 202);
    }
  }

  button {
    background-color: #53a9dc;
    border: none;
    border-radius: 5px;
    color: #ffffff;
    padding: 10px 20px;
    cursor: pointer;
    margin: 10px 0;
    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: #fdde50;
      color: black;
    }
  }

  .panelGallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 20px;
    gap: 10px;

    .panelCard {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #1e2126;
      padding: 20px;
      border-radius: 5px;
      width: calc(50% - 10px);
      box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);

      .panelCardImage {
        img {
          max-width: 100%;
          border-radius: 5px;
        }
        flex-grow: 1;  // Allows the image to expand
      }

      h2 {
        color: #fdde50;
        margin-bottom: 10px;
        text-align: left;
      }

      .panelCardDescription {
        text-align: left;
        width: 100%;
        margin: 16px 0;

        h3 {
          word-wrap: break-word;
          color: #a2a2a2;
        }
      }

      .panelVersions {
        text-align: left;
        width: 100%;
        color: #a2a2a2;

        p {
          margin: 5px 0;
        }
      }

      .panelCardButtons {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
      }
    }
  }
}

.pagination {
  display: flex;
  align-items: center;
  gap: 15px;
  color: #ffffff;

  button {
    border: none;
    border-radius: 5px;
    background-color: #282c35;
    padding: 5px 10px 5px 10px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: #53a9dc;
    }

    &::before {
      content: '';
      display: inline-block;
      width: 8px;
      height: 8px;
      border: 1px solid #ffffff;
      border-width: 0 1px 1px 0;
      transform: rotate(135deg); // Point to the left
      margin-right: 2px;
    }

    &:last-child::before {
      transform: rotate(-45deg); // Point to the right
    }
  }

  h2 {
    font-size: 18px;
    font-weight: bold;
  }
}

// mobile screen view
@media (max-width: 900px) {
  .panelCard {
    width: calc(100% - 20px) !important;  // Ensuring this rule takes precedence
  }
}
@media (max-width: 550px) {
  .panelCardButtons {
    flex-direction: column;
    padding-top: 10px;
  }
  .panelCardButtons button{
    margin: 0px;
  }
}