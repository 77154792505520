.inventoryContainer {
    display: flex;
    justify-content: center;
  }
  
  .inventoryContents {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    align-items: center;
  }
  
  .inventoryItem {
    position: relative;
    display: inline-block;
    width: 74px;
    height: 74px;
  }  
  
  .slotImage {
    position: relative;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../images/slot.png");
    background-size: cover;
  }
  
  .slotImage:hover {
    background-image: url("../../images/slot_hover.png");
  }
  
  .itemImage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    height: 70%;
  }
  
  
  .selected {
    border: 3px solid #53a9dc;
    transform: scale(1.075);
    transition: transform 0.3s ease;
    z-index: 1;
  }
  
  