// variables
$primary-color: #53a9dc;
$secondary-color: #fdde50;
$tertiary-color: #282c35;
$quaternary-color: #1e2126;
$text-color: #ffffff;
$paragraph-color: rgb(202, 202, 202);

$breakpoint-medium: 768px;
$breakpoint-mobile: 480px;

@mixin medium {
    @media (max-width: $breakpoint-medium) {
      @content;
    }
  }
  
  @mixin mobile {
    @media (max-width: $breakpoint-mobile) {
      @content;
    }
  }

  .wikiContainer {
    display: flex;
    padding: 1%;
    background-color: $tertiary-color;
    width: calc(100vw - 10px);
    margin-top: 50px;
    box-sizing: border-box;
  
    @include medium {
      flex-direction: column;
      height: auto;
      padding: 5% 2%;
    }
  
    @include mobile {
      height: auto;
      padding: 5% 2%;
    }
  }

  .wikiSectionTitle {
    display: flex;
    align-items: center;
    justify-content: left;
    flex-wrap: wrap;

    h1 {
      margin: 0;
      flex-grow: 1;
    }

    select {
      appearance: none;
      margin-left: 20px;
      padding: 5px 10px;
      font-size: 22px;
      border-radius: 5px;
      color: $text-color;
      border: 1px solid $quaternary-color;
      background-color: $quaternary-color;
      cursor: pointer;

      &:focus {
        outline: none;
        border-color: #fdde50;
      }
    }
  }

  // Override with these settings for the selected wiki section
  .selectedWikiSection {
    color: $secondary-color !important;
  }  
  
  .wikiSections {
    min-width: 20%;
    background-color: $quaternary-color;
    padding: 20px;
    box-sizing: border-box;
  
    ul {
      list-style-type: none;
      padding: 0;
      margin-top: -0.8rem; //cancel most of margin top groupTitle
  
      li {
        margin-bottom: 0.3rem;
        cursor: pointer;
        font-size: 20px;
        color: $primary-color;
  
        &:hover {
          background-color: $tertiary-color;
          border-radius: 5px;
        }

        &.groupTitle {
          cursor: default;
          font-weight: bold;
          margin-top: 1rem;
          margin-bottom: 1rem;
          font-size: 23px;
          color: $text-color;
  
          &:hover {
            color: $text-color;
            background-color: $quaternary-color;
          }
        }
      }
    }
  }
  
  .wikiContent {
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
  
    h1 {
      font-size: 28px;
      text-align: left;
      color: $text-color;
  
      @include medium {
        font-size: 20px;
      }
  
      @include mobile {
        font-size: 20px;
      }
    }
  
    p {
      font-size: 20px;
      line-height: 1.5;
      margin-top: 0.5em;
      margin-bottom: 1em;
      text-align: left;
      color: $paragraph-color;
  
      @include medium {
        font-size: 20px;
      }
  
      @include mobile {
        font-size: 20px;
      }
    }
  }
  